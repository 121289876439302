import { utils, Signer } from 'ethers';
import { defineReadOnly } from "@ethersproject/properties";
import { isFallbackModeActive } from "nomo-webon-kit";
import { nomoGetWalletAddresses, nomoSignEvmTransaction } from "nomo-webon-kit";

function appendSignatureToTx(unsignedTx: any, sigHexFromNative: string) {
    if (sigHexFromNative.length !== 130) {
        throw new Error("unexpected sigHexFromNative length");
    }
    const sigHex = sigHexFromNative.startsWith("0x") ? sigHexFromNative : "0x" + sigHexFromNative;
    return utils.serializeTransaction(unsignedTx, sigHex);
}

let cachedAddress: string | null = null;

export class EthersjsNomoSigner extends Signer {
    constructor(provider?: any) {
        super();
        defineReadOnly(this, "provider", provider || null);
    }

    connect(_provider: any) {
        return this;
    }

    async getAddress(): Promise<string> {
        if (isFallbackModeActive()) {
            throw new Error("Fallback mode is not supported in this implementation");
        }
        if (cachedAddress) {
            return Promise.resolve(cachedAddress);
        }
        return new Promise((resolve, reject) => {
            nomoGetWalletAddresses()
                .then((res) => {
                    cachedAddress = res.walletAddresses["ETH"];
                    resolve(cachedAddress);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async signMessage(_message: string): Promise<string> {
        return Promise.reject("signMessage not implemented");
    }

    async signTransaction(txRequest: any): Promise<string> {
        if (isFallbackModeActive()) {
            throw new Error("Fallback mode is not supported in this implementation");
        }

        const allowedTransactionKeys: { [key: string]: boolean } = {
            chainId: true,
            data: true,
            gasLimit: true,
            gasPrice: true,
            nonce: true,
            to: true,
            type: true,
            value: true,
        };
        const unsignedTx: { [key: string]: any } = {};
        for (const key of Object.keys(allowedTransactionKeys)) {
            if (key in txRequest) {
                unsignedTx[key] = txRequest[key];
            }
        }
        const unsignedRawTx = utils.serializeTransaction(unsignedTx);

        try {
            const res = await nomoSignEvmTransaction({ messageHex: unsignedRawTx });
            const signedRawTx = appendSignatureToTx(unsignedTx, res.sigHex);
            return signedRawTx;
        } catch (err) {
            throw err;
        }
    }
}