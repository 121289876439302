import { ethers } from "ethers";
import { abi } from "./ZenPass.json";
import { EthersjsNomoSigner } from "./ethersNomoSigner";

const zenpass_address = '0x967ce6379d65DB5d5b7027bbCfDd6f03476011ea';
const rpcUrlZeniqSmartChain = "https://smart.zeniq.network:9545";
const chainIdZeniqSmartChain = 383414847825;

const zscProvider = new ethers.providers.JsonRpcProvider(
    rpcUrlZeniqSmartChain,
    chainIdZeniqSmartChain
);
export const zscSigner = new EthersjsNomoSigner(zscProvider);
export const zenPass = new ethers.Contract(zenpass_address, abi, zscSigner);